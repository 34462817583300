import React from "react";
import Tuv from "../../images/TUV.jpg";

const Certificate = () => {

  return (
    <section className="product" id="prodcut">
      <div className="product-wrapper">
        <h3 className="font-bold text-heading-text-lg font-montserrat text-[4.4rem] mb-[4.8rem]">
          Certificate
        </h3>

        <div className=" gap-[10px] w-full">
            
  <div className="w-[100%] flex items-start justify-center">
    <p className="font-semibold mb-[0.8rem] text-[2.4rem] text-center text-body-text">
      At Seven Star Elevators, we are committed to delivering the highest quality standards in everything we do. That’s why we are proud to announce that we have achieved certification from <span className="font-extrabold">TUV South Asia</span>, a globally recognized leader in testing, certification, and inspection services.
    </p>
  </div>

  <div className="w-[100%] rounded-[12px] flex items-center justify-center ">
    <img
      src={Tuv}
      alt="Vacuum elevator by floor type"
      className="object-cover h-[70rem] w-auto py-[2.6rem]"
    />
  </div>
</div>




        <div className="product-content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[3.6rem]">
          <div className="product-image bg-background rounded-[12px] flex items-center justify-center shadow-md">
          </div>
        </div>

      </div>
    </section>
  );
};

export default Certificate;
