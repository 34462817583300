import React from 'react';

const Footer = () => {
  return (
    <footer className="footer p-[2.4rem] bg-accent-color-1 mt-[12.8rem] text-center">
      <p className="text-background text-[1.4rem] font-opensans">Copyright &#169; 2024 Seven-Star Elevators. All rights reserved.</p>
    </footer>
  )
}

export default Footer;