import React, { useState } from "react";
import {
  MdPalette,
  MdTrendingUp,
  MdHome,
  MdChair,
  MdDeviceHub,
  MdLayers,
} from "react-icons/md";

const Customization = () => {
  const [customizationOptions] = useState([
    {
      title: "Customizable Frames",
      description: "Frames can be customized to match any RAL color.",
      icon: <MdPalette />,
    },
    {
      title: "Height Capability",
      description: "Capable of reaching heights up to 15 meters (G+4).",
      icon: <MdTrendingUp />,
    },
    {
      title: "Smart Home Integration",
      description: "Features smart home integration and remote control system.",
      icon: <MdHome />,
    },
    {
      title: "Comfortable Seating",
      description:
        "Equipped with comfortable seating options for a pleasant ride.",
      icon: <MdChair />,
    },
    {
      title: "Two Variants",
      description: "Available in Split Unit and Acoustic Head Unit variants.",
      icon: <MdDeviceHub />,
    },
    {
      title: "Accurate Floor Positioning",
      description:
        "Incorporates an accurate floor positioning system for smooth stops.",
      icon: <MdLayers />,
    },
  ]);

  return (
    <section className="customization">
      <div className="customization-wrapper">
        <h3 className="font-bold text-heading-text-lg font-montserrat text-[4.4rem] mb-[4.8rem] break-words">
          Customization
        </h3>

        <div className="customization-content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[3.6rem]">
          {customizationOptions?.map((option) => (
            <div className="customization-container grid grid-cols-5" key={option.title}>
              <i className="customization-icon shadow-sm col-span-1 bg-background text-heading-text text-[3rem] p-[0.8rem] rounded-[12px] w-fit h-fit">
                {option.icon}
              </i>
              <div className="col-span-4 flex flex-col gap-[0.9rem]">
                <h5 className="text-[2rem] font-montserrat font-bold text-heading-text-lg">{option.title}</h5>
                <p className="text-[1.6rem] leading-relaxed font-opensans text-body-text">{option.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Customization;
