import React, { useState } from "react";
// import G1 from "../../images/gallery2.webp";
// import G3 from "../../images/gallery5.jpg";
// import G5 from "../../images/gallery4.jpeg";
import G5 from "../../images/gallery3.jpeg";
import G4 from "../../images/gallery1.jpg";
import G6 from "../../images/gallery6.jpeg";

const Gallery = () => {
  const [imageGallery] = useState([G4, G5, G6]);

  return (
    <section className="customization">
      <div className="customization-wrapper">
        <h3 className="font-bold text-heading-text-lg font-montserrat text-[4.4rem] mb-[4.8rem]">
          Gallery
        </h3>

        <div className="customization-content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {" "}
          {imageGallery.map((gallery) => (
            <img
              key={gallery}
              src={gallery}
              alt="Gallery of vaccum elevators"
              className="rounded-[36px] overflow-hidden p-[0.9rem] hover:scale-110 cursor-pointer transition-all duration-300 ease-in-out w-full h-[50rem] object-cover"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Gallery;
