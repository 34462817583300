import React from "react";
import { MdClose } from "react-icons/md";
import { Link } from "react-scroll";

const Sidebar = ({ navItems, activeNav, isSidebarOpen, handleClose }) => {

  return (
    <div
      className={`${
        isSidebarOpen ? "block" : "opacity-0 pointer-events-none invisible"
      } sidebar-container absolute inset-0 h-[100vh] overflow-y-hidden bg-transparent-black -1 sm:hidden grid grid-cols-3 transition-all duration-500 ease-out z-40`}
    >
      <div className="overlay" onClick={handleClose}></div>
      <div className={`sidebar-wrapper shadow-md shadow-blue-50 bg-white col-span-2 p-[1.6rem] ${isSidebarOpen ? 'animate-slide-in' : 'animate-slide-out'}`}>
        <div
          className="icon-container flex items-center justify-end text-[3.6rem] text-heading-text cursor-pointer mb-[3.6rem] w-fit ml-auto"
          onClick={handleClose}
        >
          <MdClose />
        </div>
        <nav>
          <ul className="flex flex-col justify-center text-center gap-[2.4rem]">
            {navItems.map((nav) => (
              <li
                key={nav}
                onClick={handleClose}
                className={`font-opensans text-[1.6rem] cursor-pointer text-body-text hover:text-heading-text font-semibold transition-all duration-500 ease-in-out ${
                  activeNav === nav.toLowerCase()
                    ? "custom-btn hover:text-white transition-all duration-300 ease-in-out"
                    : ""
                }`}
              >
                <Link
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-96}
                  duration={500}
                  to={`${nav.toLowerCase()}`}
                >
                  {nav}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
