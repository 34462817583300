import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { MdMenu } from "react-icons/md";
import { Sidebar } from "../index";
import useScrollLock from "../../hooks/useScrollLock";

const Header = () => {
  const [navLink] = useState(["Home", "Features", "Product", "Contact"]);
  const [isFixed, setIsFixed] = useState(false);
  const [activeNav, setActiveNav] = useState();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  function handleSetActive(currentNav) {
    setActiveNav(currentNav);
  };

  function handleOpen(){
    lockScroll();
    setIsSidebarOpen(true);
  } 

  function handleClose(){
    unlockScroll();
    setIsSidebarOpen(false);
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const heroSectionHeight = 600;

      if (scrollPosition > heroSectionHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`header flex justify-between items-center rounded-br-[24px] rounded-bl-[24px] p-[1.6rem] mb-[2.4rem] h-[9.6rem] transition-all duration-500 ease-in-out ${
        isFixed
          ? "sticky inset-0 z-20 bg-transparent-white shadow-blue-50 shadow-md"
          : ""
      }`}
    >
      <a href="/" className="w-[24rem] h-[8rem] object-contain p-[1.6rem]">
        <img
          src="/logo.svg"
          alt="seven star logo"
        />
      </a>

      <nav>
        <ul className="hidden sm:flex items-center justify-center gap-[2.4rem]">
          {navLink.map((nav) => (
            <li
              key={nav}
              className={`font-opensans text-[1.6rem] cursor-pointer text-body-text hover:text-heading-text font-semibold transition-all duration-500 ease-in-out ${
                activeNav === nav.toLowerCase()
                  ? "custom-btn hover:text-white transition-all duration-300 ease-in-out"
                  : ""
              }`}
            >
              <Link
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-96}
                duration={500}
                to={`${nav.toLowerCase()}`}
                onSetActive={handleSetActive}
              >
                {nav}
              </Link>
            </li>
          ))}
        </ul>
      </nav>

      <div className="block sm:hidden text-[3.6rem] text-heading-text font-bold cursor-pointer">
        <MdMenu onClick={handleOpen} />
      </div>

      <Sidebar
        navItems={navLink}
        activeNav={activeNav}
        isSidebarOpen={isSidebarOpen}
        handleClose={handleClose}
      />
    </header>
  );
};

export default Header;
