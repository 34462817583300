import React, { useRef, useState } from "react";
import {
  MdMeetingRoom,
  MdBuild,
  MdFlashOn,
  MdSafetyCheck,
  MdArrowCircleLeft,
  MdArrowCircleRight,
} from "react-icons/md";
import PVEWhite from "../../images/pve-white-2.png";
import PVEBlack from "../../images/pve-black-2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Features = () => {
  const sliderRef = useRef();
  const [featuresList] = useState([
    {
      title: "Pitless Installation",
      description:
        "Enjoy the advantage of a vacuum lift that requires no pit, making it suitable for both new and existing buildings.",
      icon: <MdMeetingRoom />,
    },
    {
      title: "Low Maintenance",
      description:
        "Benefit from a maintenance-friendly solution as our vacuum elevators operate without the need for oils or lubrication.",
      icon: <MdBuild />,
    },
    {
      title: "Quick Installation",
      description:
        "Experience rapid installation within 2-3 days, minimizing disruption and allowing you to enjoy your new elevator promptly.",
      icon: <MdFlashOn />,
    },
    {
      title: "Uncompromised Safety",
      description:
        "With advanced safety features, our vacuum elevators ensure maximum protection during power outages and provide a free fall within just 60mm.",
      icon: <MdSafetyCheck />,
    },
  ]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function handlePrevious(){
    sliderRef.current.slickPrev();
  }

  function handleNext(){
    sliderRef.current.slickNext();
  }
  return (
    <section className="features mt-[12.8rem]" id="features">
      <div className="features-wrapper">
        <h3 className="font-bold text-heading-text-lg font-montserrat text-[4.4rem] mb-[4.8rem]">
          Features
        </h3>

        <div className="features__content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-[3.6rem]">
          <ul className="features__content__list col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-[3.6rem]">
            {featuresList.map((feature) => (
              <li key={feature.title} className="flex flex-col gap-[1.8rem]">
                <i className="bg-background shadow-sm text-heading-text text-[3rem] p-[0.8rem] rounded-[12px] w-fit">
                  {feature.icon}
                </i>
                <h5 className="text-[2rem] font-montserrat font-bold text-heading-text-lg">
                  {feature.title}
                </h5>
                <p className="text-[1.6rem] leading-relaxed font-opensans text-body-text">
                  {feature.description}
                </p>
              </li>
            ))}
          </ul>

          <div className="features__content__img col-span-1 md:col-span-2 lg:col-span-1 bg-background rounded-[12px] relative shadow-md">
            <button className="cursor-pointer" onClick={handlePrevious}>
              <MdArrowCircleLeft className="absolute text-[3.6rem] z-10 inset-y-1/2 left-0 mx-[1.6rem] text-heading-text-lg opacity-75" />
            </button>
            <button className="cursor-pointer" onClick={handleNext}>
              <MdArrowCircleRight className="absolute text-[3.6rem] z-10 inset-y-1/2 right-0 mx-[1.6rem] text-heading-text-lg opacity-75" />
            </button>
            <Slider {...settings} ref={sliderRef}>
              <img
                src={PVEWhite}
                alt="Pneumatic Vacuum Elevators black"
                className="w-[40rem] h-[50rem] object-contain"
              />
              <img
                src={PVEBlack}
                alt="Pneumatic Vacuum Elevators black"
                className="w-[40rem] h-[50rem] object-contain"
              />
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
