import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import {
  MdLocationOn,
  MdPhone,
  MdMail,
  MdCheck,
  MdClose,
} from "react-icons/md";

const Contact = () => {
  const contactForm = useRef();

  const contactDetails = [
    {
      label: "Address",
      value:
        "No.33 SS Enclave, Vaishnavi Nagar, Tiruvallur Street, Karanaipuduchery, Chengalpattu District, Tamil Nadu-603202",
      icon: <MdLocationOn />,
    },
    {
      label: "Phone",
      value: "+919677324237, +919360524258, 044-27178258",
      icon: <MdPhone />,
    },
    {
      label: "Email",
      value: "director@sevenstarelevators.com, sales@sevenstarelevators.com",
      icon: <MdMail />,
    },
  ];

  const [formData, setFormData] = useState({
    client_name: "",
    client_email: "",
    client_phone: "",
    client_message: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();

    // Reset the form after submission
    setFormData({
      client_name: "",
      client_email: "",
      client_phone: "",
      client_message: "",
    });

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        contactForm.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        () => {
          toast.success(
            <p className="font-opensans text-[1.6rem] ml-[0.8rem] text-body-text font-normal">
              Form Submitted! Thank You
            </p>,
            {
              icon: (
                <span className="text-[2rem] font-bold text-white shadow-sm bg-success rounded-full p-[0.4rem]">
                  <MdCheck />
                </span>
              ),
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        },
        () => {
          toast.error(
            <p className="font-opensans text-[1.6rem] ml-[0.8rem] text-body-text font-normal">
              Oops! Try Again
            </p>,
            {
              icon: (
                <span className="text-[2rem] font-bold text-white shadow-sm bg-error rounded-full p-[0.4rem]">
                  <MdClose />
                </span>
              ),
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      );
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <section className="contact" id="contact">
      <div className="contact-wrapper">
        <h3 className="font-bold text-heading-text-lg font-montserrat text-[4.4rem] mb-[4.8rem]">
          Contact
        </h3>

        <div className="contact-content mb-[4.8rem] text-center">
          <div className="contact-details grid grid-cols-1 md:grid-cols-3 gap-[3.6rem] items-center justify-center">
            {contactDetails.map((detail) => (
              <div
                className="contact-info flex flex-col items-center justify-center gap-[1.6rem] text-center"
                key={detail.label}
              >
                <div className="bg-background rounded-full shadow-sm w-fit h-fit p-[0.8rem]">
                  <i className="text-heading-text text-[3rem] rounded-[12px] cursor-pointer">
                    {detail.icon}
                  </i>
                </div>
                <p className="text-[1.6rem] font-montserrat font-bold text-heading-text-lg">
                  {detail.label}
                </p>
                <p className="text-[1.4rem] leading-relaxed font-opensans text-body-text">
                  {detail.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Form */}
      <h4 className="text-center font-bold text-heading-text-lg font-montserrat text-[3rem] mb-[3.6rem]">
        Get in touch with us
      </h4>
      <form
        ref={contactForm}
        onSubmit={sendEmail}
        className="flex flex-col gap-[1.6rem]"
      >
        <div>
          <input
            className="form-input"
            type="text"
            id="client_name"
            name="client_name"
            placeholder="Name"
            value={formData.client_name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input
            className="form-input"
            type="email"
            id="client_email"
            name="client_email"
            placeholder="Email"
            value={formData.client_email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input
            className="form-input"
            type="tel"
            id="client_phone"
            name="client_phone"
            placeholder="Phone"
            value={formData.client_phone}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <textarea
            className="form-input"
            id="client_message"
            name="client_message"
            placeholder="Message"
            value={formData.client_message}
            onChange={handleChange}
            required
          />
        </div>
        <button
          type="submit"
          className="bg-accent-color-1 text-background text-[1.6rem] font-semibold font-opensans rounded-[12px] w-full h-[40px] mt-[2.4rem]"
        >
          Send Message
        </button>
      </form>

      <ToastContainer />
    </section>
  );
};

export default Contact;
