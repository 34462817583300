import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./layout/RootLayout";


const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
    }
  ])
  return (
    <RouterProvider router={router} />
  );
};

export default App;
